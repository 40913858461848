import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

class EditorMaterals {
  constructor(canvas, option) {
    console.log(canvas, 'canvascanvascanvas222222');
    this.canvas = canvas;
    this.workspaceEl = document.querySelector('#threejs-container');
    this.workspace = null;
    this.option = option;
    console.log(this.option, 'this.option');
    console.log(this.canvas, 'this.canvascanvas');
    this._c();
    this._createTemplate();
    this._addGrid();
    // this._addCanvasToScene();
  }

  // 设置相机
  _c() {
    const container = document.querySelector('#threejs-container');
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    this.renderer.setClearColor(0xffffff, 1); // 设置背景色
    container.appendChild(this.renderer.domElement);

    this.scene = new THREE.Scene();

    // 创建一个 Group 来包含所有的场景对象
    this.sceneGroup = new THREE.Group();
    this.scene.add(this.sceneGroup);

    // const originX = -15;
    // const originY = -10;
    // const originZ = -8;
    // this.sceneGroup.position.set(originX, originY, originZ);
    // 设置相机

    // this.camera = new THREE.OrthographicCamera(-25, 25, 12.5, -12.5, 0.1, 1000); // 正交投影相机
    this.camera = new THREE.PerspectiveCamera(
      75,
      container.clientWidth / container.clientHeight,
      0.1,
      1000
    ); //
    this.camera.position.set(0, 30, 100); // 相机位置

    // 添加环境光（Ambient Light）以确保所有地方都有基础亮度
    const ambientLight = new THREE.AmbientLight(0x404040, 1); // 灰色环境光
    this.sceneGroup.add(ambientLight);

    // 添加定向光（Directional Light）模拟太阳光
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.7);
    directionalLight.position.set(2, 1, 0).normalize();
    this.sceneGroup.add(directionalLight); // 将光源添加到场景组中

    // 初始化 OrbitControls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.target = new THREE.Vector3(20, 20, 0); // 控制焦点
    // this.controls.target.copy(this.sceneGroup.position);
    this.controls.enableDamping = false; // 启用阻尼效果，使控制更平滑
    // this.controls.dampingFactor = 0.001; // 阻尼系数
    this.controls.rotateSpeed = 0.2;
    this.controls.enableZoom = true; // 启用缩放
    this.controls.enablePan = false; // 禁用平移
    this.controls.minPolarAngle = 0; // 最小极角 (0 度)
    this.controls.maxPolarAngle = Math.PI / 3; // 最大极角 (90 度)
    this.controls.enableRotate = true; // 启用旋转
    this.controls.minDistance = 1; // 最小距离
    this.controls.maxDistance = 100; // 最大距离

    // 设置整体绕 X 轴旋转 90 度
    // this.sceneGroup.rotation.x = Math.PI / 2;

    this.animate();
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
    this.controls.update();
  };

  // _calculateModelPosition = (xOffset, yOffset, zOffset) => {
  //   return new THREE.Vector3(-8 + xOffset, yOffset, zOffset);
  // };

  _createTemplate() {
    console.log(
      this.option.width,
      this.option.height,
      this.option.dept,
      '   this.option.width,  this.option.height,this.option.dept'
    );
    const width = 20;
    const height = 1;
    const dept = 20;
    // 创建模板几何体
    const templateGeometry = new THREE.BoxGeometry(width, height, dept); // 长30cm, 宽20cm, 高1cm
    const templateMaterial = new THREE.MeshStandardMaterial({
      color: 'white', // 使用十六进制颜色代码代替字符串
      metalness: 0.3, // 设置金属度
      roughness: 0.7, // 设置粗糙度
    });
    const templateMesh = new THREE.Mesh(templateGeometry, templateMaterial);
    // 将模板放置在场景中心
    // templateMesh.position.set(-10.5, 2.5, -0.5);
    templateMesh.position.set(width / 2, height / 2, -dept / 2); // 左下角位置
    this.sceneGroup.add(templateMesh); // 添加到场景组中
    // const box = new THREE.Box3().setFromObject(templateMesh);
    // const center = new THREE.Vector3();
    // box.getCenter(center);
    // // 更新 OrbitControls 的目标位置为模型的中心位置
    // this.controls.target.copy(center);
    // // 如果 sceneGroup 的位置不是 (0, 0, 0)，则需要将 controls.target 移动相同的偏移量
    // this.controls.target.add(this.sceneGroup.position);
    // // 强制更新控制器状态以反映更改
    // this.controls.update();
  }

  _addGrid() {
    const axesHelper = new THREE.AxesHelper(50); // 10 是坐标轴长度
    this.sceneGroup.add(axesHelper);
    const gridSize = 30; // 网格大小为 300 mm
    const divisions = 30; // 300 mm / 10 mm per division = 30 divisions
    // const spacing = gridSize / divisions; // 每格间距为 10 mm
    // 添加 XZ 平面的网格
    const gridHelperXZ = new THREE.GridHelper(gridSize, divisions, 0x444444, 0x888888);
    // gridHelperXZ.rotation.x = Math.PI / 2; // 旋转网格使其平行于 XZ 平面
    gridHelperXZ.position.set(gridSize / 2, 0, -gridSize / 2); // 移动网格至左下角
    this.sceneGroup.add(gridHelperXZ);

    // 如果需要 XY 或 YZ 平面的网格，可以类似地创建并添加
    // 注意：根据您的需求调整网格的位置和旋转

    // 加载字体
    const loader = new FontLoader();
    loader.load(
      '/fonts/Arial_Regular.json', // 字体文件路径
      (font) => {
        console.log('Font loaded:', font); // 输出加载的字体对象，用于调试
        for (let i = 0; i <= divisions; i++) {
          const x = i;
          // const z = gridSize / 2;

          // 创建 X 轴上的标注
          const textGeoX = new TextGeometry(i.toString(), {
            font,
            size: 0.3, // 文本大小
            depth: 0, // 文本厚度
            curveSegments: 12, // 字符曲线分段数
            bevelEnabled: false, // 是否启用倒角
          });

          const textMatX = new THREE.MeshBasicMaterial({ color: 'red' });
          const textMeshX = new THREE.Mesh(textGeoX, textMatX);
          textMeshX.position.set(x, 0, 0.5); // 放置在网格下方一点
          textMeshX.rotation.x = -Math.PI / 2; // 旋转使文本面向上
          this.sceneGroup.add(textMeshX);

          // const zPos = -gridSize / 2 + i * spacing;

          // 创建 Z 轴上的标注
          const textGeoZ = new TextGeometry((divisions - i).toString(), {
            font,
            size: 0.3, // 文本大小
            height: 0, // 文本厚度
            curveSegments: 12, // 字符曲线分段数
            bevelEnabled: false, // 是否启用倒角
          });
          const textMatZ = new THREE.MeshBasicMaterial({ color: 'red' });
          const textMeshZ = new THREE.Mesh(textGeoZ, textMatZ);
          textMeshZ.position.set(0 - 0.5, 0, i - gridSize); // 放置在网格左侧一点
          textMeshZ.rotation.x = -Math.PI / 2; // 旋转使文本面向右
          this.sceneGroup.add(textMeshZ);
        }
      },
      undefined, // onProgress callback
      (err) => {
        console.error('An error happened while loading the font:', err);
      }
    );
  }

  // _addCanvasToScene() {
  //   // 获取 Canvas 元素
  //   const canvasElement = this.canvas.getObjects();
  //   // 创建一个 Three.js 组来保存所有的图形
  //   const graphicsGroup = new THREE.Group();

  //   // 遍历所有的 Fabric.js 图形对象
  //   canvasElement.forEach((fabricObj) => {
  //     // 根据 fabricObj 类型创建合适的 Three.js 几何体
  //     let geometry = null;
  //     let material = null;
  //     let mesh = null;

  //     switch (fabricObj.type) {
  //       case 'rect': // 如果是矩形
  //         geometry = new THREE.BoxGeometry(
  //           fabricObj.width,
  //           fabricObj.height,
  //           fabricObj.fill ? 0.1 : 0
  //         ); // 使用 fill 判断是否有深度
  //         material = new THREE.MeshStandardMaterial({ color: fabricObj.fill || 0xffffff });
  //         mesh = new THREE.Mesh(geometry, material);
  //         break;

  //       case 'circle': // 如果是圆形
  //         geometry = new THREE.CylinderGeometry(
  //           fabricObj.radius,
  //           fabricObj.radius,
  //           fabricObj.fill ? 0.1 : 0
  //         );
  //         material = new THREE.MeshStandardMaterial({ color: fabricObj.fill || 0xffffff });
  //         mesh = new THREE.Mesh(geometry, material);
  //         break;

  //       // 添加其他类型的图形...

  //       default:
  //         console.warn(`Unsupported shape type: ${fabricObj.type}`);
  //         return;
  //     }

  //     // 设置位置和旋转以匹配 Fabric.js 对象
  //     mesh.position.set(fabricObj.left, fabricObj.top, 0);
  //     mesh.rotation.set(0, 0, (-fabricObj.angle * Math.PI) / 180); // 注意角度单位转换

  //     // 添加到组中
  //     graphicsGroup.add(mesh);
  //   });

  //   // 将所有图形添加到场景中的模板上方
  //   graphicsGroup.position.set(0, 1, 0); // 调整z轴位置以便位于模板上方
  //   this.sceneGroup.add(graphicsGroup);

  //   // 更新场景
  //   this.renderer.render(this.scene, this.camera);
  // }
}

export default EditorMaterals;
