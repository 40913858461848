<template>
  <el-tabs
    type="border-card"
    v-if="mSelectMode === 'one' || mSelectMode === 'multiple'"
    @tab-click="tabClick"
  >
    <el-tab-pane label="Shape">
      <div v-show="baseType.includes(mSelectOneType)">
        <Divider plain orientation="left">{{ $t('attributes.exterior') }}</Divider>
        <div class="flex-view">
          <div class="flex-item">
            <span class="label">{{ $t('attributes.left') }}</span>
            <div class="content">
              <InputNumber
                v-model="baseAttr.left"
                :disabled="isLock"
                :max="360"
                @on-change="(value) => changeCommon('left', value)"
                show-input
              ></InputNumber>
            </div>
          </div>
          <div class="flex-item">
            <span class="label">{{ $t('attributes.top') }}</span>
            <div class="content">
              <InputNumber
                v-model="baseAttr.top"
                :disabled="isLock"
                :max="360"
                @on-change="(value) => changeCommon('top', value)"
                show-input
              ></InputNumber>
            </div>
          </div>
        </div>
        <Divider plain orientation="left">{{ $t('attributes.angle') }}</Divider>

        <div class="flex-view">
          <div class="flex-item">
            <div class="content slider-box">
              <Slider
                v-model="baseAttr.angle"
                :disabled="isLock"
                :max="360"
                @on-input="(value) => changeCommon('angle', value)"
              ></Slider>
            </div>
          </div>
        </div>
        <Divider plain orientation="left">{{ $t('size') }}</Divider>
        <div class="flex-view">
          <div class="flex-item">
            <span class="size-label">{{ $t('attributes.width') }}</span>
            <div class="size-content">
              <InputNumber
                v-model="baseAttr.width"
                :disabled="isLock"
                @on-change="(value) => changeCommon('width', value)"
                show-input
              ></InputNumber>
            </div>
          </div>
          <div class="flex-item">
            <span class="size-label">{{ $t('attributes.height') }}</span>
            <div class="size-content">
              <InputNumber
                v-model="baseAttr.height"
                :disabled="isLock"
                @on-change="(value) => changeCommon('height', value)"
                show-input
              ></InputNumber>
            </div>
          </div>
        </div>
        <div v-if="mSelectOneType === 'rect'">
          <Divider plain orientation="left">{{ $t('attributes.radius') }}</Divider>
          <div class="flex-view">
            <div class="flex-item">
              <span class="size-label">{{ $t('attributes.rx') }}</span>
              <div class="size-content">
                <InputNumber
                  v-model="baseAttr.rx"
                  :disabled="isLock"
                  @on-change="(value) => changeCommon('rx', value)"
                  show-input
                ></InputNumber>
              </div>
            </div>
            <div class="flex-item">
              <span class="size-label">{{ $t('attributes.ry') }}</span>
              <div class="size-content">
                <InputNumber
                  v-model="baseAttr.ry"
                  :disabled="isLock"
                  @on-change="(value) => changeCommon('ry', value)"
                  show-input
                ></InputNumber>
              </div>
            </div>
          </div>
        </div>

        <div v-if="mSelectOneType === 'text'">
          <Divider plain orientation="left">{{ $t('attributes.font') }}</Divider>
          <div style="position: relative; text-align: center; background: #f6f7f9">
            <el-dropdown trigger="click" @command="handleFontSelect">
              <el-button>{{ fontAttr.fontFamily }}</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in fontFamilyList"
                  :key="index"
                  :command="item"
                >
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <Divider plain orientation="left">{{ $t('attributes.effect') }}</Divider>
          <div style="position: relative; text-align: center; background: #f6f7f9">
            <el-dropdown trigger="click" @command="handleFontSelect">
              <el-button>{{ fontAttr.fontFamily }}</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in fontFamilyList"
                  :key="index"
                  :command="item"
                >
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- </div> -->
          <!-- </div> -->
        </div>

        <Button v-if="isLock" @click="doLock(false)" icon="md-lock" type="text"></Button>
        <Button v-else @click="doLock(true)" icon="md-unlock" type="text"></Button>
      </div>
      <!-- <set-size></set-size> -->
      <!-- <lock></lock> -->
    </el-tab-pane>
    <el-tab-pane label="Cut" v-if="showLaser">
      <div style="display: flex">
        <div class="left-cut">
          <Divider plain orientation="left">{{ $t('attributes.dept') }}</Divider>
          <el-input v-model.number="sliderValue" @change="inputChange"></el-input>
          <div v-if="sliderValue === maxDept ? true : false">
            <Divider plain orientation="left">{{ $t('attributes.adddepth') }}</Divider>
            <el-select v-model="addDepthValue">
              <el-option
                v-for="item in addDepthOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div style="margin-top: 10px">
            <img src="@/assets/images/L11square.png" alt="" style="width: 40px" />
            <div>{{ $t('attributes.shift') }}</div>
            <el-input type="text" v-model="shiftValue"></el-input>
          </div>
        </div>
        <div class="right-cut">
          <el-slider
            v-model="sliderValue"
            :min="0"
            :max="maxDept"
            height="300px"
            vertical
            :marks="marks"
            @change="sliderChange"
            @input="sliderInput"
          ></el-slider>
        </div>
      </div>
      <div>
        <Divider plain orientation="left">{{ $t('attributes.cutpath') }}</Divider>
        <div>
          <div class="flex-view">
            <div class="flex-item">
              <div class="size-content">
                <div style="position: relative; text-align: center; background: #f6f7f9">
                  <el-dropdown trigger="click" @command="handleFontSelect">
                    <img src="@/assets/images/L11square.png" alt="" style="width: 40px" />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <img src="@/assets/images/L11square.png" alt="" style="width: 40px" />
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <img src="@/assets/images/L11square.png" alt="" style="width: 40px" />
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <img src="@/assets/images/L11square.png" alt="" style="width: 40px" />
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="size-content">
                <el-button type="primary" @click="changeCommon('fill', '#57606B')">
                  {{ $t('attributes.fill') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Laser" v-if="!showLaser">
      <el-table :data="tableData" border style="width: 100%" @row-click="rowClick">
        <el-table-column prop="layer" label="Layer" width="60" align="center"></el-table-column>
        <el-table-column prop="mode" label="Mode" width="100" align="center">
          <template slot-scope="scope">
            <el-select v-model="scope.row.mode" placeholder="请选择" @change="selectChange">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="speed"
          label="Speed/Power"
          align="center"
          width="130"
        ></el-table-column>
      </el-table>
      <div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="speed">
            <InputNumber
              v-model="attribute.speed"
              @on-change="(value) => speedChange(value)"
              show-input
            ></InputNumber>
          </el-form-item>
          <el-form-item label="Power">
            <InputNumber
              v-model="attribute.power"
              @on-change="(value) => powerChange(value)"
              show-input
            ></InputNumber>
          </el-form-item>
          <el-form-item label="Pass">
            <InputNumber
              v-model="attribute.pass"
              @on-change="(value) => passChange(value)"
              show-input
            ></InputNumber>
          </el-form-item>
          <el-form-item label="Interval">
            <InputNumber
              v-model="attribute.interval"
              @on-change="(value) => intervalChange(value)"
              show-input
            ></InputNumber>
          </el-form-item>
        </el-form>
        <!-- <ul>
          <li>
            speed:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Power:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Pass:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
          <li>
            Interval:
            <InputNumber
              v-model="baseAttr.rx"
              :disabled="isLock"
              @on-change="(value) => changeCommon('rx', value)"
              show-input
            ></InputNumber>
          </li>
        </ul> -->
        <Divider plain orientation="left">{{ $t('attributes.strokefill') }}</Divider>
        <div class="flex-view">
          <div class="flex-item">
            <div class="size-content">
              <el-button type="primary" @click="changeCommon('stroke', '#57606B')">
                {{ $t('attributes.stroke') }}
              </el-button>
            </div>
          </div>
          <div class="flex-item">
            <div class="size-content">
              <el-button type="primary" @click="changeCommon('fill', '#57606B')">
                {{ $t('attributes.fill') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import fontList from '@/assets/fonts/font';
import select from '@/mixins/select';
import FontFaceObserver from 'fontfaceobserver';
// import attribute from '@/components/attribute.vue';

export default {
  name: 'on-shape',
  mixins: [select],
  //   components: {
  //     attribute,
  //   },
  data() {
    return {
      maxDept: 50,
      sliderValue: 0,
      shiftValue: 0,
      marks: {
        100: '100',
        80: '80',
        60: '60',
        40: '40',
        20: '20',
        0: '0',
      },
      addDepthValue: 0,
      addDepthOptions: [
        {
          value: 0,
          label: 'none',
        },
        {
          value: 0.2,
          label: '0.2mm',
        },
        {
          value: 0.5,
          label: '0.5mm',
        },
        {
          value: 1,
          label: '1mm',
        },
        {
          value: 3,
          label: '3mm',
        },
      ],
      isLock: false,
      baseType: [
        'polygon',
        'text',
        'i-text',
        'textbox',
        'rect',
        'circle',
        'triangle',
        'image',
        'group',
        'line',
        'arrow',
      ],
      // 通用属性
      baseAttr: {
        opacity: 0,
        angle: 0,
        fill: '#fff',
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        rx: 0,
        ry: 0,
        strokeWidth: 0,
        strokeDashArray: [],
        stroke: '#fff',
        shadow: {
          color: '#fff',
          blur: 0,
          offsetX: 0,
          offsetY: 0,
        },
      },
      // 字体属性
      fontAttr: {
        fontSize: 0,
        fontFamily: '',
        lineHeight: 0,
        charSpacing: 0,
        fontWeight: '',
        textBackgroundColor: '#fff',
        textAlign: '',
        fontStyle: '',
        underline: false,
        linethrough: false,
        overline: false,
      },
      // 字体下拉列表
      fontFamilyList: fontList.map((item) => item.fontFamily),
      showLaser: false,
      options: [
        {
          value: 'Line',
          label: 'Stroke',
        },
        {
          value: 'fill',
          label: 'Fill',
        },
      ],
      tableData: [],
      attribute: {
        speed: 0,
        power: 0,
        pass: 0,
        interval: 0,
      },
      clickRow: {},
      form: {},
      clickActive: null,
    };
  },
  created() {
    this.event.on('selectCancel', () => {
      this.baseAttr.fill = '';
      this.$forceUpdate();
    });
    this.event.on('selectOne', () => {
      const [clickActive] = this.canvas.c.getActiveObjects();
      this.clickActive = clickActive;
      console.log(this.clickActive, 'this.clickActive');
      this.sliderValue = this.clickActive.dept ? this.clickActive.dept : 0;
      const activeObject = this.canvas.c.getActiveObjects()[0];

      if (activeObject.zIndex === '00') {
        this.showLaser = true;
      } else {
        this.showLaser = false;
      }
      if (activeObject) {
        console.log(this.mSelectOneType, 'this.mSelectOneType');

        // base
        this.baseAttr.opacity = activeObject.get('opacity') * 100;
        this.baseAttr.fill = activeObject.get('fill');
        this.baseAttr.left = Number(activeObject.get('left').toFixed(2));
        this.baseAttr.top = Number(activeObject.get('top').toFixed(2));
        this.baseAttr.width = Number(activeObject.get('width').toFixed(2));
        this.baseAttr.height = Number(activeObject.get('height').toFixed(2));

        this.baseAttr.stroke = activeObject.get('stroke');
        this.baseAttr.strokeWidth = activeObject.get('strokeWidth');
        this.baseAttr.shadow = activeObject.get('shadow') || {};
        this.baseAttr.angle = activeObject.get('angle') || 0;
        if (this.mSelectOneType === 'rect') {
          this.baseAttr.rx = Number(activeObject.get('rx').toFixed(2));
          this.baseAttr.ry = Number(activeObject.get('ry').toFixed(2));
        }
        const textTypes = ['i-text', 'text', 'textbox'];
        if (textTypes.includes(activeObject.type)) {
          this.fontAttr.fontSize = activeObject.get('fontSize');
          this.fontAttr.fontFamily = activeObject.get('fontFamily');
          this.fontAttr.lineHeight = activeObject.get('lineHeight');
          this.fontAttr.textAlign = activeObject.get('textAlign');
          this.fontAttr.underline = activeObject.get('underline');
          this.fontAttr.linethrough = activeObject.get('linethrough');
          this.fontAttr.charSpacing = activeObject.get('charSpacing');
          this.fontAttr.overline = activeObject.get('overline');
          this.fontAttr.fontStyle = activeObject.get('fontStyle');
          this.fontAttr.textBackgroundColor = activeObject.get('textBackgroundColor');
          this.fontAttr.fontWeight = activeObject.get('fontWeight');
        }

        // 图片滤镜
        // if (activeObject.type === 'image') {
        //   this.imgAttr.blur = activeObject.filters[0] ? activeObject.filters[0].blur : 0;
        // }
      }
    });
    this.event.on('selectMultiple', () => {
      const ssss = this.canvas.c.getActiveObjects();
      console.log(ssss, 'ssssssssss');
    });
  },
  mounted() {},
  methods: {
    getColor() {
      // 计算颜色深度
      // const grayValue = Math.round((this.maxDept - this.sliderValue) * 2.55);
      const a = 255 / this.maxDept;
      const grayValue = (this.maxDept - this.sliderValue) * a;
      // 计算颜色深度
      // const grayValue = 100 - mappedValue;
      return `rgb(${grayValue}, ${grayValue}, ${grayValue})`;
    },
    sliderInput(e) {
      console.log(e, 'eee');
      this.sliderValue = e;
      // if(this.activeObject)
      this.clickActive.set('fill', this.getColor());
      console.log(this.clickActive, 'this.clickActive');

      this.canvas.c.renderAll();
    },
    // 滑块拖动
    sliderChange(e) {
      console.log(e, 'eee');
      this.clickActive.set('dept', e);
    },
    // 深度设置
    inputChange(e) {
      console.log(e, 'eeeeeeeee');
      this.clickActive.set('dept', e);
    },
    // laser table点击
    rowClick(row) {
      console.log(row, 'rowrow');
      this.clickRow = row;
      this.attribute = { ...this.clickRow };
      // this.attribute.speed = this.attribute.speed.split('/')[0];
    },
    // 填充和描边选择
    selectChange(e) {
      console.log(e, 'eeee');
    },
    speedChange(e) {
      this.clickActive.set('speed', e);
      this.clickRow.speed = e;
    },
    powerChange(e) {
      this.clickActive.set('power', e);
      this.clickRow.power = e;
    },
    passChange(e) {
      this.clickActive.set('pass', e);
      this.clickRow.pass = e;
    },
    intervalChange(e) {
      this.clickActive.set('interval', e);
      this.clickRow.interval = e;
    },
    // tab切换
    tabClick(e) {
      if (e.label === 'Laser') {
        const newArray = this.canvas.c.getObjects().slice(1);
        console.log(newArray, 'newArraynewArraynewArray');

        this.tableData = newArray
          .map((item) => {
            if (item.zIndex !== '00') {
              return {
                layer: item.zIndex,
                mode: item.fill === null ? 'stroke' : 'fill',
                // speed: `${this.attribute.speed} / ${this.attribute.power}`,
                speed: item.speed ? item.speed : 0,
                power: item.power ? item.power : 0,
                pass: item.pass ? item.pass : 0,
                interval: item.interval ? item.interval : 0,
              };
            }
            return null;
          })
          .filter((item) => item !== null);
        this.attribute.speed = this.clickActive.speed ? this.clickActive.speed : 0;
        this.attribute.power = this.clickActive.power ? this.clickActive.power : 0;
        this.attribute.pass = this.clickActive.pass ? this.clickActive.pass : 0;
        this.attribute.interval = this.clickActive.interval ? this.clickActive.interval : 0;

        console.log(this.tableData, 'newArraysss');
      }
    },
    // 字体切换
    handleFontSelect(e) {
      this.fontAttr.fontFamily = e;
      const font = new FontFaceObserver(e);
      font.load(null, 150000).then(() => {
        const activeObject = this.canvas.c.getActiveObjects()[0];
        activeObject.set('fontFamily', e);
        this.canvas.c.renderAll();
      });
      // const activeObject = this.canvas.c.getActiveObjects()[0];
      // console.log(activeObject, 'activeObjectactiveObject');

      // activeObject && activeObject.set('fontFamily', e);
      // this.canvas.c.renderAll();
      // this.$Spin.hide();
      // console.log(activeObject, 'activeObjectactiveObject');
      // console.log(e, 'e');
    },
    // 锁定
    doLock(isLock) {
      isLock ? this.lock() : this.unLock();
    },
    lock() {
      this.isLock = true;
      this.isLock = true;
      this.canvas.c.renderAll();
    },
    unLock() {
      this.isLock = false;
      this.isLock = false;
      this.canvas.c.renderAll();
    },
    // 通用属性改变
    changeCommon(key, value) {
      const activeObject = this.canvas.c.getActiveObjects()[0];
      const colors1 = this.canvas.c.getActiveObjects()[0].stroke;
      const colors2 = this.canvas.c.getActiveObjects()[0].fill;
      console.log(colors1, colors2, 'colors1colors1');

      // 透明度特殊转换
      if (key === 'opacity') {
        activeObject && activeObject.set(key, value / 100);
        this.canvas.c.renderAll();
        return;
      }
      // 旋转角度适配
      if (key === 'angle') {
        activeObject.rotate(value);
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'rx' || key === 'ry') {
        activeObject.set('ry', value);
        activeObject.set('rx', value);
        activeObject.set('roundValue', value);
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'stroke') {
        activeObject.set('fill', null); // 移除填充
        activeObject.set('stroke', colors2); // 设置描边颜色
        activeObject.set('strokeWidth', 2); // 设置描边宽度
        this.canvas.c.renderAll();
        return;
      }
      if (key === 'fill') {
        activeObject.set('fill', colors1); // 移除填充
        activeObject.set('stroke', null); // 设置描边颜色
        this.canvas.c.renderAll();
        return;
      }
      activeObject && activeObject.set(key, value);
    },
  },
};
</script>

<style lang="less" scoped>
.el-dropdown-menu {
  max-height: 50%;
  overflow-y: scroll;
}
.flex-view {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  display: inline-flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #f6f7f9;
}
.flex-item {
  display: inline-flex;
  flex: 1;
  .label {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    font-size: 14px;
    color: #333333;
  }
  .content {
    width: 60px;
  }
  .size-label {
    width: 40px;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    font-size: 14px;
    color: #333333;
  }
  .size-content {
    width: 40px;
    .ivu-input-number {
      width: 60px;
    }
  }
  .slider-box {
    width: calc(100% - 50px);
    margin-left: 10px;
  }
  .right {
    margin-left: 10px;
    /deep/ .ivu-input-number {
      display: block;
      width: 100%;
    }
  }
  /deep/ .ivu-slider-wrap {
    margin: 13px 0;
  }
  /deep/ .ivu-radio-group-button .ivu-radio-wrapper {
    width: 48px;
    line-height: 40px;
    text-align: center;
    svg {
      vertical-align: baseline;
    }
  }

  /deep/ .ivu-btn-group-large > .ivu-btn {
    font-size: 24px;
  }

  /deep/ .ivu-radio-group-button.ivu-radio-group-large .ivu-radio-wrapper {
    font-size: 24px;
  }
}
.left-cut {
  width: 50%;
}
.right-cut {
  width: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
}
/deep/ .el-slider .is-vertical {
  transform: rotateX(180deg);
  display: inline-block;
}

/deep/ .el-slider__bar {
  transform: rotateX(180deg);
}

/deep/ .el-slider__button {
  transform: rotateX(180deg);
}
</style>
