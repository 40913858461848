<template>
  <div>
    <!-- <Divider plain orientation="left">{{ $t('size') }}</Divider>
      <Form :label-width="40">
        <FormItem :label="$t('width')" prop="name">
          <InputNumber :max="2000" :min="1" v-model="width" @on-change="setSize"></InputNumber>
        </FormItem>
        <FormItem :label="$t('height')" prop="name">
          <InputNumber :max="2000" :min="1" v-model="height" @on-change="setSize"></InputNumber>
        </FormItem>
      </Form> -->
    <!-- <Divider plain orientation="left">{{ $t('default_size') }}</Divider>
      <ButtonGroup vertical>
        <Button
          v-for="(item, i) in presetSize"
          :key="i + 'presetSize'"
          size="small"
          style="text-align: left"
          @click="setSizeBy(item.width, item.height)"
        >
          {{ item.label }}:{{ item.width }}x{{ item.height }}
        </Button>
      </ButtonGroup> -->
  </div>
</template>

<script>
import select from '@/mixins/select';
import EditorMaterals from '@/core/addTemplate';

export default {
  name: 'canvasSize',
  mixins: [select],
  inject: ['canvas', 'fabric', 'THREE'],
  data() {
    return {
      width: 1000,
      height: 900,
      dept: 20,
      presetSize: [
        {
          label: this.$t('red_book_vertical'),
          width: 500,
          height: 500,
          dept: 20,
        },
        {
          label: this.$t('red_book_horizontal'),
          width: 1200,
          height: 900,
          dept: 20,
        },
        {
          label: this.$t('phone_wallpaper'),
          width: 1080,
          height: 1920,
          dept: 20,
        },
      ],
    };
  },
  mounted() {
    this.canvas.editor.editorWorkspace = new EditorMaterals(this.canvas.c, {
      dept: this.dept,
      width: this.width,
      height: this.height,
    });
  },
  methods: {
    setSizeBy(width, height, dept) {
      this.width = width;
      this.height = height;
      this.dept = dept;
      this.setSize();
    },
    setSize() {
      EditorMaterals(this.width, this.height, this.dept);
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ivu-form-item {
  margin-bottom: 0;
}
/deep/ .ivu-divider-plain.ivu-divider-with-text-left {
  margin: 10px 0;
  font-weight: bold;
}
</style>
