// svgService.js
import axios from 'axios';
// import fs from 'fs';
// import { PNG } from 'pngjs';
// import potrace from 'potrace';

async function fetchPngAndConvertToSvg(url) {
  //   try {
  // 下载 PNG 文件
  const response = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  console.log(response, 'responseresponse');

  // 读取 PNG 数据
  // const png = new PNG();
  // png.parse(Buffer.from(response.data));

  // 使用 potrace 将 PNG 转换为 SVG
  //     const svg = await new Promise((resolve, reject) => {
  //       potrace.trace(png.data, (err, svgs) => {
  //         if (err) return reject(err);
  //         resolve(svgs);
  //       });
  //     });
  //     console.log(svg);
  //     return svg;
  //   } catch (error) {
  //     console.error('Error converting PNG to SVG:', error);
  //     throw error;
  //   }
}

export default fetchPngAndConvertToSvg;
