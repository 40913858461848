import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/home/index.vue';

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true,
  },
  {
    path: '/signup',
    component: () => import('@/views/signup/index.vue'),
    hidden: true,
  },
  {
    path: '/reset',
    component: () => import('@/views/reset/index.vue'),
    hidden: true,
  },
  {
    path: '/google',
    component: () => import('@/views/google/index.vue'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true,
  },
  { path: '*', redirect: '/404', hidden: true },
];

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
