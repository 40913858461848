/* eslint import/prefer-default-export: "off" */
import request from '@/utils/projectRequest';

// 项目导入
export function importPro(data) {
  return request({
    url: '/api/project/file/import',
    method: 'post',
    data,
  });
}
