import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
/* eslint-disable import/no-cycle */
import user from './modules/user';
/* eslint-disable import/no-cycle */
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
  },
  getters,
});

export default store;
