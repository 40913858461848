<template>
  <div class="home">
    <Layout>
      <Userdialog
        :dialogVisible.sync="dialogVisible"
        @close-visible="closeVisable"
        @project-data="updateProject"
        class="child-element"
      ></Userdialog>
      <Header v-if="show" class="el-header">
        <Questiondialog
          v-if="firstLogin"
          @update-first-login="handleFirstLoginChange"
          :show-tools="showTools"
        ></Questiondialog>
        <!-- logo -->
        <img
          src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
          alt=""
          style="width: 60px"
          @click="imgClick"
        />
        <div style="font-size: 40px; margin: 0 20px">LaCraft</div>
        <!-- 上方菜单 -->
        <div>
          <el-dropdown @command="handleCommand" trigger="click">
            <el-button
              type="primary"
              @mouseover="changeBackgroundColor(true)"
              @mouseleave="changeBackgroundColor(false)"
            >
              File
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="New">New</el-dropdown-item>
              <el-dropdown-item command="Open">Open</el-dropdown-item>
              <el-dropdown-item command="Import">Import</el-dropdown-item>
              <el-dropdown-item command="Export">Export</el-dropdown-item>
              <el-dropdown-item command="Share">Share</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <el-dropdown @command="handleCommand" trigger="click">
            <el-button
              type="primary"
              @mouseover="changeBackgroundColor(true)"
              @mouseleave="changeBackgroundColor(false)"
            >
              Setup
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="New Maschine">New Maschine</el-dropdown-item>
              <el-dropdown-item command="General Settings">General Settings</el-dropdown-item>
              <el-dropdown-item command="Download Driver">Download Driver</el-dropdown-item>
              <el-dropdown-item command="Bits/Lasers">Bits/Lasers</el-dropdown-item>
              <el-dropdown-item command="Marterials">Marterials</el-dropdown-item>
              <el-dropdown-item command="Cut Settings">Cut Settings</el-dropdown-item>
              <el-dropdown-item command="Machines">Machines</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div>
          <el-dropdown @command="handleCommand" trigger="click">
            <el-button
              type="primary"
              @mouseover="changeBackgroundColor(true)"
              @mouseleave="changeBackgroundColor(false)"
            >
              Help
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="GuideBook">GuideBook</el-dropdown-item>
              <el-dropdown-item command="VideoGallery">VideoGallery</el-dropdown-item>
              <el-dropdown-item command="NewUserGuide">NewUserGuide</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 导入 -->
        <import-JSON></import-JSON>
        <Divider type="vertical" />
        <import-file></import-file>
        <Divider type="vertical" />
        <!-- 颜色开关 -->
        <Tooltip :content="$t('grid')">
          <iSwitch v-model="ruler" size="small" class="switch"></iSwitch>
        </Tooltip>
        <Divider type="vertical" />
        <history></history>

        <div style="float: right">
          <save></save>
          <lang></lang>
        </div>
        <set-size></set-size>
        <setThreeSize></setThreeSize>
      </Header>
      <Content style="display: flex; height: calc(100vh - 64px)">
        <!-- <div v-if="show" style="width: 380px; height: 100%; background: #fff; display: flex"> -->
        <!-- <Menu
            :active-name="menuActive"
            accordion
            @on-select="(activeIndex) => (menuActive = activeIndex)"
            width="65px"
          >
            <MenuItem :name="1" class="menu-item">
              <Icon type="md-book" size="24" />
              <div>{{ $t('templates') }}</div>
            </MenuItem>
            <MenuItem :name="2" class="menu-item">
              <Icon type="md-images" size="24" />
              <div>{{ $t('elements') }}</div>
            </MenuItem>
            <MenuItem :name="3" class="menu-item">
              <Icon type="md-reorder" size="24" />
              <div>{{ $t('layers') }}</div>
            </MenuItem>
          </Menu> -->
        <!-- <div class="content"> -->
        <!-- 生成模板 -->
        <!-- <div v-show="menuActive === 1" class="left-panel"> -->
        <!-- <import-tmpl></import-tmpl> -->
        <!-- </div> -->
        <!-- 常用元素 -->
        <!-- <div v-show="menuActive === 2" class="left-panel">
              <tools></tools>
              <svgEl></svgEl>
            </div> -->
        <!-- 背景设置 -->
        <!-- <div v-show="menuActive === 3" class="left-panel">
              <layer></layer>
            </div>
          </div>
        </div> -->
        <!-- 画布区域 -->
        <div id="workspace" style="width: 50%; position: relative; background: #f1f1f1">
          <tools :save-project="projectData" v-if="showTools" @canvas-url="getCanvasUrl"></tools>
          <div class="canvas-box">
            <div class="inside-shadow"></div>
            <div v-if="ruler" class="coordinates-bar coordinates-bar-top" style="width: 50%"></div>
            <div
              v-if="ruler"
              class="coordinates-bar coordinates-bar-left"
              style="height: 100%"
            ></div>
            <!-- class design-stage-point 点状  design-stage-grid 棋盘 -->
            <canvas id="canvas" :class="ruler ? 'design-stage-grid' : ''"></canvas>
            <zoom></zoom>
            <mouseMenu></mouseMenu>
          </div>

          <div
            style="
              width: 250px;
              height: 40%;
              /* padding: 10px; */
              overflow-y: auto;
              position: absolute;
              z-index: 999;
              top: 0;
              right: 0;
            "
          >
            <Shape></Shape>
            <!-- <el-tabs type="border-card">
              <el-tab-pane label="Shape">
                <attribute v-if="show"></attribute>
                <set-size></set-size>
                <lock></lock>
              </el-tab-pane>
              <el-tab-pane label="Cut">Cut</el-tab-pane>
              <el-tab-pane label="Laser">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column
                    prop="layer"
                    label="Layer"
                    width="60"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="mode"
                    label="Mode"
                    width="60"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    prop="speed"
                    label="Speed/Power"
                    align="center"
                  ></el-table-column>
                </el-table>
                <div>
                  <ul>
                    <li>speed:5000m/s</li>
                    <li>Power:80%</li>
                    <li>Pass:1</li>
                    <li>Interval:0.1mm</li>
                  </ul>
                </div>
              </el-tab-pane>
            </el-tabs> -->
            <!-- <bg-bar></bg-bar> -->
            <!-- <group></group> -->
            <!-- <div class="attr-item"> -->
            <!-- <lock></lock> -->
            <!-- <dele></dele> -->
            <!-- <clone></clone> -->
            <!-- </div> -->
            <!-- 组对齐方式 -->
            <!-- <align></align> -->
            <!-- 居中对齐 -->
            <!-- <center-align></center-align> -->
            <!-- 翻转 -->
            <!-- <flip></flip> -->
          </div>
          <div
            style="
              /* padding: 10px; */
              overflow-y: auto;
              position: absolute;
              z-index: 999;
              left: 5px;
              bottom: 5px;
            "
          >
            <layerssss></layerssss>
          </div>
        </div>
        <div id="three" style="width: 50%; position: relative; background: #f1f1f1">
          <div id="threejs-container" ref="threejsContainer"></div>
        </div>
        <!-- 属性区域 380-->
      </Content>
    </Layout>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { importPro } from '@/api/import';
import { exportPro } from '@/api/export';
/* eslint-disable import/no-cycle */
import { selectFiles } from '@/utils/utils';
import { v4 as uuid } from 'uuid';

// 导入元素
import importJSON from '@/components/importJSON.vue';
import importFile from '@/components/importFile.vue';

// 顶部组件
import Shape from '@/components/Shape.vue';
import layerssss from '@/components/layerssss.vue';
// import align from '@/components/align.vue';
// import centerAlign from '@/components/centerAlign.vue';
// import flip from '@/components/flip.vue';
import save from '@/components/save.vue';
import lang from '@/components/lang.vue';
// import clone from '@/components/clone.vue';
// import group from '@/components/group.vue';
import zoom from '@/components/zoom.vue';
// import lock from '@/components/lock.vue';
// import dele from '@/components/del.vue';

// 左侧组件
// import importTmpl from '@/components/importTmpl.vue';
import tools from '@/components/tools.vue';
// import svgEl from '@/components/svgEl.vue';
// import bgBar from '@/components/bgBar.vue';
import setSize from '@/components/setSize.vue';

// 右侧组件
import history from '@/components/history.vue';
// import layer from '@/components/layer.vue';
// import attribute from '@/components/attribute.vue';

// 右键菜单
import mouseMenu from '@/components/contextMenu/index.vue';

// 功能组件
import EventHandle from '@/utils/eventHandler';
// import addTemplate from '@/core/addTemplate';

import { fabric } from 'fabric';
import * as THREE from 'three';
import Editor from '@/core';
import setThreeSize from '@/components/setThreeSize.vue';

import Questiondialog from './components/Questiondialog/index.vue';
import Userdialog from './components/Userdialog/index.vue';

const event = new EventHandle();
const canvas = {};
const renderer = {};
export default {
  name: 'HomeView',
  provide: {
    canvas,
    fabric,
    event,
    renderer,
    THREE,
  },
  data() {
    return {
      canvasUrl: '',
      showTools: false,
      projectData: {},
      tableData: [
        {
          layer: 1,
          mode: 2,
          speed: 3,
        },
        {
          layer: 1,
          mode: 2,
          speed: 3,
        },
      ],
      menuActive: 1,
      show: false,
      select: null,
      ruler: false,
      dialogVisible: true,
      firstLogin: false,
    };
  },
  components: {
    setSize,
    setThreeSize,
    tools,
    Shape,
    layerssss,
    // bgBar,
    // lock,
    // layer,
    // align,
    // attribute,
    // dele,
    importFile,
    save,
    lang,
    importJSON,
    // clone,
    // flip,
    // importTmpl,
    // centerAlign,
    // group,
    zoom,
    // svgEl,
    history,
    mouseMenu,
    Questiondialog,
    Userdialog,
  },
  created() {
    this.$Spin.show();
  },
  mounted() {
    this.canvas = new fabric.Canvas('canvas', {
      fireRightClick: true, // 启用右键，button的数字为3
      stopContextMenu: true, // 禁止默认右键菜单
      controlsAboveOverlay: true, // 超出clipPath后仍然展示控制条
    });
    canvas.c = this.canvas;
    event.init(canvas.c);
    canvas.editor = new Editor(canvas.c);
    canvas.c.renderAll();
    this.show = true;
    this.$Spin.hide();
    // 3D
    const container = this.$refs.threejsContainer;
    // 设置 Three.js 渲染器尺寸
    container.style.width = '100%';
    container.style.height = '100%';
    console.log(container.clientHeight, 'container.clientHeight');
  },
  methods: {
    getCanvasUrl(canvasUrl) {
      this.canvasUrl = canvasUrl;
    },
    // 双击project获取project数据
    updateProject(newData) {
      this.projectData = newData;
    },
    closeVisable() {
      this.dialogVisible = false;
      this.showTools = true;
    },
    imgClick() {
      this.showTools = false;
      this.dialogVisible = true;
    },
    handleFirstLoginChange(value) {
      this.firstLogin = value;
      // 更新localStorage
      localStorage.setItem('firstLogin', this.firstLogin);
    },
    changeBackgroundColor(isHovered) {
      if (isHovered) {
        this.buttonColor = '#3a8ee6'; // 鼠标悬停时的背景色
      } else {
        this.buttonColor = '#409EFF'; // 默认背景色
      }
    },
    handleCommand(command) {
      if (command === 'New') {
        const objects = this.canvas.getObjects();
        objects.forEach((obj) => {
          if (obj.id !== 'workspace') {
            this.canvas.remove(obj);
          }
        });
        this.canvas.renderAll();
        localStorage.setItem('canvasID', null);
        this.$emit('close-visible');
        const project = {
          id: 0,
        };
        this.updateProject(project);
      } else if (command === 'Open') {
        this.imgClick();
      } else if (command === 'Import') {
        selectFiles({ accept: '.lac', multiple: true }).then((fileList) => {
          if (fileList && fileList.length > 0) {
            // const promises = Array.fom(fileList).map((file) => );
            const data = fileList[0];
            const formData = new FormData();
            formData.append('file', data);
            console.log(fileList, 'fileList');
            console.log(fileList[0].name, 'fileList');
            importPro(formData).then((res) => {
              fabric.loadSVGFromString(res.data, (objects) => {
                const filteredObjects = objects.filter(
                  (obj) => obj.id !== 'workspace' && (!obj.fill || obj.fill !== '#F1F1F1')
                );
                filteredObjects.forEach((obj) => {
                  this.canvas.add(obj);
                });
                this.canvas.renderAll();
              });
              // this.insertSvgFile();
              // console.log(res.data, 'resssssssssssssss');
            });
          } else {
            console.log('没有选择文件');
          }
        });
      } else if (command === 'Export') {
        // const workspace = this.canvas.c.getObjects().find((item) => item.id === 'workspace');
        // const objects = this.canvas.c.getObjects();
        const workspace = this.canvas.getObjects().find((item) => item.id === 'workspace');
        const { left, top, width, height } = workspace;
        const dataUrl = this.canvas.toSVG({
          width,
          height,
          viewBox: {
            x: left,
            y: top,
            width,
            height,
          },
        });
        const data = {
          id: localStorage.getItem('canvasID'),
          name: `项目${localStorage.getItem('canvasID')}`,
          userId: localStorage.getItem('id'),
          canvasUrl: this.canvasUrl,
          materialInfo: {
            // 材料尺寸
            simension: '{"X":"900 mm","Y":"1200 mm","Z":"19 mm"}',
            previewUrl: this.canvasUrl,
            color: '#FF0000',
            extInfo: '',
          },
          // 设备id
          machineId: 1,
          previewUrl: this.canvasUrl,
        };
        exportPro(data).then((res) => {
          console.log(res, 'res');

          if (res.code === 200) {
            const apiData = res.data;
            const fullSvgContent = `${apiData}\n${dataUrl}`;
            const fileStr = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
              fullSvgContent
            )}`;
            this.downFile(fileStr, 'lac');
          }
        });
      }
      console.log(`选择了: ${command}`);
      // 在这里可以添加处理选择项的逻辑
    },
    downFile(fileStr, fileType) {
      const anchorEl = document.createElement('a');
      anchorEl.href = fileStr;
      anchorEl.download = `${uuid()}.${fileType}`;
      document.body.appendChild(anchorEl); // required for firefox
      anchorEl.click();
      anchorEl.remove();
    },
  },
};
</script>
<style lang="less" scoped>
// 属性面板样式
/deep/ .attr-item {
  position: relative;
  margin-bottom: 12px;
  height: 40px;
  padding: 0 10px;
  background: #f6f7f9;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .ivu-tooltip {
    text-align: center;
    flex: 1;
  }
}

.ivu-menu-vertical .menu-item {
  text-align: center;
  padding: 10px 2px;
  box-sizing: border-box;
  font-size: 12px;

  & > i {
    margin: 0;
  }
}

::v-deep .ivu-layout-header {
  height: 60px;
  padding: 0 10px;
  border-bottom: 1px solid #eef2f8;
  background: #fff;
  height: var(--height);
  line-height: var(--height);
  display: flex;
  color: #333;
  font-size: large;
  background-color: #2a82e4;
  color: #333;
}
.el-header {
  line-height: 60px;
}

.home,
.ivu-layout {
  height: 100vh;
}

.icon {
  display: block;
}

.canvas-box {
  position: relative;
}

.inside-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 15px 5px blue;
  box-shadow: inset 0 0 9px 2px #0000001f;
  z-index: 2;
  pointer-events: none;
}

#canvas {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  // background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=");
  // background-size: 30px 30px;
}

#workspace {
  overflow: hidden;
}

.content {
  flex: 1;
  width: 220px;
  padding: 10px;
  padding-top: 0;
  height: 100%;
  overflow-y: auto;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
  background: none;
}
// 标尺与网格背景
.switch {
  margin-right: 10px;
}
.design-stage-point {
  --offsetX: 0px;
  --offsetY: 0px;
  --size: 20px;
  background-size: var(--size) var(--size);
  background-image: radial-gradient(circle, #2f3542 1px, rgba(0, 0, 0, 0) 1px);
  background-position: var(--offsetX) var(--offsetY);
}

.design-stage-grid {
  // dom.style.setProperty('--offsetX', `${point.x + e.clientX}px`) 通过修改 偏移量 可实现跟随鼠标效果 --size 则为间距
  // dom.style.setProperty('--offsetY', `${point.y + e.clientY}px`)
  --offsetX: 0px;
  --offsetY: 0px;
  --size: 16px;
  --color: #dedcdc;
  background-image: linear-gradient(
      45deg,
      var(--color) 25%,
      transparent 0,
      transparent 75%,
      var(--color) 0
    ),
    linear-gradient(45deg, var(--color) 25%, transparent 0, transparent 75%, var(--color) 0);
  background-position: var(--offsetX) var(--offsetY),
    calc(var(--size) + var(--offsetX)) calc(var(--size) + var(--offsetY));
  background-size: calc(var(--size) * 2) calc(var(--size) * 2);
}

.coordinates-bar {
  --ruler-size: 16px;
  --ruler-c: #808080;
  --rule4-bg-c: #252525;
  --ruler-bdw: 1px;
  --ruler-h: 8px;
  --ruler-space: 5px;
  --ruler-tall-h: 16px;
  --ruler-tall-space: 15px;
  position: absolute;
  z-index: 2;
  background-color: var(--rule4-bg-c);
}
.coordinates-bar-top {
  cursor: row-resize;
  top: 0;
  left: 0;
  height: var(--ruler-size);
  width: 100%;
  background-image: linear-gradient(90deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0),
    linear-gradient(90deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0);
  background-repeat: repeat-x;
  background-size: var(--ruler-space) var(--ruler-h), var(--ruler-tall-space) var(--ruler-tall-h);
  background-position: bottom;
}
.coordinates-bar-left {
  cursor: col-resize;
  top: var(--ruler-size);
  width: var(--ruler-size);
  height: 100%;
  left: 0;
  background-image: linear-gradient(0deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0),
    linear-gradient(0deg, var(--ruler-c) 0 var(--ruler-bdw), transparent 0);
  background-repeat: repeat-y;
  background-size: var(--ruler-h) var(--ruler-space), var(--ruler-tall-h) var(--ruler-tall-space);
  background-position: right;
}
.el-button--primary {
  background-color: #2a82e4; /* 鼠标悬停时的背景色 */
}
.el-button {
  color: white;
  border: none;
  transition: background-color 0.3s ease;
  color: black;
}
.el-button:hover {
  background-color: #3a8ee6; /* 鼠标悬停时的背景色 */
}

.el-dropdown-menu__item {
  color: #303133;
}

.el-dropdown-menu__item:hover {
  background-color: #ecf5ff; /* 下拉菜单项鼠标悬停时的背景色 */
  color: #409eff;
}
.el-tabs--border-card > .el-tabs__content {
  padding: 5px;
}
</style>
