<template>
  <!-- <div class="container"> -->
  <el-dialog
    title="Design Library"
    :visible.sync="vis"
    width="40%"
    :before-close="handleClose"
    :modal="false"
  >
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item style="width: 80%">
        <el-input v-model="formInline.user"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <el-tabs tab-position="left" @tab-click="tabClick" v-model="Animal">
      <el-tab-pane v-for="(item, index) in tabPane" :key="index" :label="item" :name="item">
        <div class="image-container" ref="imageContainer">
          <div v-for="(item, index) in imageList" :key="index" class="image-item" ref="imageItem">
            <el-image
              :src="item.previewUrl"
              class="image"
              :alt="item.altText || ''"
              @dblclick="handleImageDoubleClick(item.previewUrl)"
            />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
  <!-- </div> -->
</template>

<script>
import { getLibeaetList, getLibeaetType, getLibeaetTag } from '@/api/library';
import fetchPngAndConvertToSvg from '@/utils/potrace';

export default {
  name: 'design-library',
  inject: ['canvas', 'fabric'],
  props: {
    library: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      vis: this.library,
      formInline: {
        user: '',
      },
      type: '',
      Animal: '',
      tag: '',
      pageNo: 1,
      pageSize: 36,
      tabPane: [],
      imageList: [],
    };
  },
  watch: {
    library(newVal) {
      this.vis = newVal;
      if (this.vis === true) {
        this.getType();
      }
    },
    vis(newVal) {
      this.$emit('update:library', newVal);
    },
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('close-visible');
    },
    onSubmit() {},
    // 获取素材库列表
    getList() {
      const data = {
        type: this.type,
        tag: this.tag,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      getLibeaetList(data).then((res) => {
        if (res.code === 200) {
          this.imageList = res.data;
          this.imageList.forEach((item, index) => {
            this.getImageDimensions(item.previewUrl, (width, height) => {
              if (width && height) {
                console.log(`Image dimensions for ${item.previewUrl}:`, width, height);
                // 如果需要在获取尺寸后立即设置容器大小
                const container = this.$refs.imageItem[index];
                if (container) {
                  if (height > width) {
                    // 图片高度大于宽度，以高度为 150px
                    const imgWidth = `${width / (height / 150)}px`;
                    container.querySelector('.image').style.width = imgWidth;
                  }
                }
              }
            });
          });
        }
      });
    },
    async getType() {
      await getLibeaetType().then((res) => {
        if (res.code === 200) {
          this.tabPane = res.data;
          [this.type] = this.tabPane;
          [this.Animal] = this.tabPane;
        }
        console.log(res, 'ressss');
      });
      await this.getList();
    },
    getTag() {
      getLibeaetTag().then((res) => {
        console.log(res, 'ressssd');
      });
    },
    tabClick(e) {
      this.type = e.label;
      this.getList();
    },
    getImageDimensions(url, callback) {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        callback(img.naturalWidth, img.naturalHeight);
      };
      img.onerror = (error) => {
        console.error('Failed to load image:', error);
        callback(null, null);
      };
    },
    // 双击图片
    async handleImageDoubleClick(e) {
      const svgString = await fetchPngAndConvertToSvg(e);
      console.log(svgString, 'svgStringsvgString');

      this.fabric.loadSVGFromString(svgString, (objects) => {
        const filteredObjects = objects.filter(
          (obj) => obj.id !== 'workspace' && (!obj.fill || obj.fill !== '#F1F1F1')
        );
        console.log(objects, 'objectsobjects');
        console.log(filteredObjects, 'optionsoptions');
        // const group = this.fabric.util.groupSVGElements(filteredObjects, options);
        filteredObjects.forEach((obj) => {
          this.canvas.c.add(obj);
        });
        this.canvas.c.renderAll();
      });
      console.log(e, 'eeee');
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .v-modal {
  display: none;
}
::v-deep .el-form-item__content {
  width: 100%;
}
// .el-tab-pane {
//   display: flex;
// }
.image-container {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
}

.image-item {
  width: calc(100% / 6); /* 每个图像占据六分之一的宽度 */
  height: 150px; /* 固定高度 */
  box-sizing: border-box; /* 确保 padding 和 border 不增加元素的总宽度 */
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中 */
  text-align: center;
  padding: 5px; /* 可选：添加一些间距 */
  position: relative; /* 使 .image 相对于 .image-item 定位 */
}

.image {
  width: 100%; /* 占据整个 .image-item 容器的宽度 */
  object-fit: contain; /* 保持图像的宽高比，等比例缩放 */
}
.el-image__inner {
}
::v-deep .el-dialog {
  height: 80%;
  overflow: hidden;
}
::v-deep .el-dialog__body {
  height: 100%;
}
::v-deep .el-tabs--left {
  height: 100%;
}
::v-deep .el-tabs__content {
  height: 90%;
  overflow-y: scroll;
}
</style>
